import React from "react"

const NotFound = () => {
  return (
    <div>
      <p>アクセスしたページは存在しません。</p>
    </div>
  )
}

export default NotFound
